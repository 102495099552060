import type { FeedbackProps } from "@enzymefinance/ui";
import { Feedback } from "@enzymefinance/ui";
import { NoActivity } from "components/images/NoActivity";

type EmptyStateProps = FeedbackProps;

export function EmptyState({
  children = "Looks like there is nothing there yet",
  title = "Nothing there yet",
  ...props
}: EmptyStateProps) {
  return (
    <Feedback image={<NoActivity />} title={title} {...props}>
      {children}
    </Feedback>
  );
}
