import type { ComponentPropsWithoutRef } from "react";

type NoActivityProps = ComponentPropsWithoutRef<"svg">;

export function NoActivity(props: NoActivityProps) {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg viewBox="0 0 374 229.632" fill="none" {...props}>
      <g filter="url(#filter0_dd)">
        <rect y="0.442871" width="374" height="229.632" rx="7.7513" fill="#919BBF" fillOpacity="0.05" />
        <rect x="38.2876" y="167.096" width="38.7565" height="38.7565" rx="19.3782" fill="#919BBF" fillOpacity="0.3" />
        <circle cx="85.3695" cy="77.202" r="44.4715" fill="url(#paint0_linear)" />
        <path
          d="M137.566 46.4984C143.878 57.2295 146.73 69.6455 145.732 82.0555C144.734 94.4655 139.935 106.266 131.989 115.851C124.043 125.436 113.336 132.338 101.326 135.619C89.3163 138.899 76.5869 138.399 64.8718 134.184L85.3695 77.202L137.566 46.4984Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M47.9015 39.734C53.6218 34.0137 60.576 29.6791 68.231 27.0625C75.8859 24.4459 84.0384 23.6169 92.0634 24.6389C100.088 25.6609 107.773 28.5069 114.528 32.9586C121.282 37.4103 126.928 43.3496 131.032 50.3211L85.3692 77.2017L47.9015 39.734Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M67.8991 127.226C60.8976 124.781 54.4874 120.894 49.083 115.815L85.3692 77.2017L67.8991 127.226Z"
          fill="url(#paint3_linear)"
        />
        <g clipPath="url(#clip0)">
          <rect x="167.274" y="115.744" width="44.57" height="19.3782" rx="9.68912" fill="#EA66D8" />
        </g>
        <g clipPath="url(#clip1)">
          <rect x="219.596" y="115.744" width="45.5389" height="19.3782" rx="9.68912" fill="#6AE0C0" />
        </g>
        <g clipPath="url(#clip2)">
          <rect x="272.886" y="115.744" width="37.7876" height="19.3782" rx="9.68912" fill="#A989F7" />
        </g>
        <rect x="97.5127" y="169.034" width="114.332" height="14.5337" rx="7.26684" fill="#919BBF" fillOpacity="0.3" />
        <rect x="167.274" y="58.5781" width="114.332" height="27.1295" rx="7.7513" fill="#919BBF" fillOpacity="0.3" />
        <rect
          opacity="0.4"
          x="97.5127"
          y="190.35"
          width="175.373"
          height="15.5026"
          rx="7.7513"
          fill="#919BBF"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="0.621758"
          y="0.442868"
          width="412.756"
          height="268.389"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology radius="4.84456" operator="erode" in="SourceAlpha" result="effect1_dropShadow" />
          <feOffset dy="9.68912" />
          <feGaussianBlur stdDeviation="4.84456" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology radius="4.84456" operator="erode" in="SourceAlpha" result="effect2_dropShadow" />
          <feOffset dy="19.3782" />
          <feGaussianBlur stdDeviation="12.1114" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="-48.0451"
          y1="32.7305"
          x2="83.0656"
          y2="204.357"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CE9FFC" />
          <stop offset="1" stopColor="#7367F0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="47.1296"
          y1="47.9197"
          x2="141.114"
          y2="128.339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7FBFF" />
          <stop offset="1" stopColor="#F641CE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="95.7962"
          y1="13.8375"
          x2="109.14"
          y2="86.6759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#85FFA7" />
          <stop offset="1" stopColor="#2B97FA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="32.3818"
          y1="-81.7604"
          x2="244.331"
          y2="130.189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#85A0FF" />
          <stop offset="1" stopColor="#2BAFFA" />
        </linearGradient>
        <clipPath id="clip0">
          <rect x="167.274" y="115.744" width="44.57" height="19.3782" rx="9.68912" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect x="219.596" y="115.744" width="45.5389" height="19.3782" rx="9.68912" fill="white" />
        </clipPath>
        <clipPath id="clip2">
          <rect x="272.886" y="115.744" width="37.7876" height="19.3782" rx="9.68912" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
